import MainNavBar from "../../navigation/components/MainNavBar";
import UploadContainer from "../../upload/containers/UploadContainer";
import RapprochementsContainer from "../../rapprochements/containers/RapprochementsContainer";
import LoansContainer from "../../loans/LoansContainer";
import FacturesPanelSelector from "../../factures/components/FacturesPanelSelector";
import GrandLivreMainPanel from "../../grandLivre/GrandLivreMainPanel";
import VracContainer from "../../vrac/VracContainer";
import ImmobilisationsContainer from "../../immobilisations/components/ImmobilisationsContainer";
import LaunchTasks from "../../tasks/LaunchTasks";
import SettingsPanel from "../../settings/SettingsPanel";
import CSGCRDSPanel from "../../csgcrds/CSGCRDSPanel";
import ODsContainer from "../../ods/ODsContainer";
import A2035 from "../../2035/2035A";
import BalancePanel from "../../exports/BalancePanel";
import { Route, Switch, useLocation } from "wouter";
import { useState } from "react";
import FecPanel from "../../grandLivre/FecPanel.tsx";
import LibellesContainer from "../../libellés/LibellesContainer.tsx";

const MainMenuNames = [
  "upload",
  "factures",
  "rapprochements",
  "emprunts",
  "grandLivre",
  "immobilisations",
  "ODs",
  "URSSAF",
  "vrac",
  "processing",
  "labels",
  "2035",
  "balance",
  "FEC",
  "params",
] as const;

export type MainMenuNamesType = (typeof MainMenuNames)[number];

function isMainMenuName(name: string): name is MainMenuNamesType {
  return MainMenuNames.includes(name as MainMenuNamesType);
}

function locationToMainMenuName(location: string): MainMenuNamesType | null {
  const path = location.split("/")[1];
  if (isMainMenuName(path)) {
    return path;
  }
  return null;
}

const MainContainer = () => {
  const [location, setLocation] = useLocation();
  const [year, setYear] = useState(new Date().getFullYear());
  console.log("location", location);

  const handleSetLocation = (loc: MainMenuNamesType) => {
    setLocation("~/" + loc);
  };
  console.log("location", location);
  const nav = locationToMainMenuName(location);
  return (
    <div>
      <div className="container-fluid">
        <div className={"row"}>
          <MainNavBar
            navSelected={nav}
            selectNav={handleSetLocation}
            navNames={MainMenuNames}
            year={year}
            onSetYear={setYear}
          />
        </div>
        <div>
          <Switch>
            <Route path="/upload" component={UploadContainer} />
            <Route path="/factures" nest>
              <FacturesPanelSelector defaultYear={year} />
            </Route>
            <Route
              path="/rapprochements"
              component={RapprochementsContainer}
              nest
            />
            <Route path="/emprunts" component={LoansContainer} nest />
            <Route path="/grandLivre" component={GrandLivreMainPanel} nest />
            <Route
              path="/immobilisations"
              component={ImmobilisationsContainer}
            />
            <Route path="/ODs">
              <ODsContainer yearSelected={year} />
            </Route>
            <Route path="/urssaf" nest>
              <CSGCRDSPanel yearSelected={year} />
            </Route>
            <Route path="/vrac" component={VracContainer} />
            <Route path="/processing" component={LaunchTasks} />
            <Route path="/params" component={SettingsPanel} />
            <Route path="/labels" nest>
              <LibellesContainer />
            </Route>
            <Route path="/2035" nest>
              <A2035 yearSelected={year} />
            </Route>
            <Route path="/balance" nest>
              <BalancePanel yearSelected={year} />
            </Route>

            <Route path="/FEC" nest>
              <FecPanel yearSelected={year} />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default MainContainer;
