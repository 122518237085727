import React from "react";
import FactureInput from "./FactureInput2";
import VentilationMainPanel from "./VentilationMainPanel";
import { Modal } from "react-bootstrap";
import { useFacture } from "../hooks/useFacture";
import FactureRèglementsComp from "./FactureRèglementsComp";
import { trpc } from "../../main/components/ProviderContainer.tsx";
import factureStateToFacture from "../processing/factureStateToFacture.ts";
import { ExistingFacture3, factureValidate } from "compta-shared";

interface MainFactureInputPanelProps {
  selectedFacture: ExistingFacture3 | null;
  onDone: () => void;
}

const MainFactureInputPanel = (props: MainFactureInputPanelProps) => {
  const { facture, ventilationsProps, factureProps, règlementsProps } =
    useFacture(props.selectedFacture || null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [ventilationModalOpen, setVentilationModalOpen] = React.useState(false);
  const [showAlertSuccess, setShowAlertSuccess] = React.useState(false);
  const [showAlertError, setShowAlertError] = React.useState(false);
  const {
    ventilations,
    totalActuelVentilationsCent,
    ventilationTotalTargetCent,
    ventilationsErrors,
    onSetCurrentVentilationSelected,
    onUpdateVentilation,
    onDeleteVentilation,
    onDeleteAllVentilations,
    onAcceptVentilations,
  } = ventilationsProps;

  const { setIsNotTouched, isTouched } = factureProps;

  console.log("isTouched", isTouched);
  const handleCancelVentilations = () => {
    setVentilationModalOpen(false);
  };

  const handleDeleteAllVentilations = () => {
    setVentilationModalOpen(false);
    onDeleteAllVentilations();
    onSetCurrentVentilationSelected(null);
  };

  const handleAcceptVentilations = () => {
    setVentilationModalOpen(false);
    onAcceptVentilations();
    onSetCurrentVentilationSelected(null);
  };
  const onCancelEditFacture = () => {
    factureProps.onCancelCurrentFacture();
    props.onDone();
  };
  const onOpenGeneralVentilations = () => {
    setVentilationModalOpen(true);
    onSetCurrentVentilationSelected("main");
  };
  const onOpenLineVentilations = (idx: number) => {
    onSetCurrentVentilationSelected(idx);
    setVentilationModalOpen(true);
  };
  const showRèglementsInput = facture.règlements.length > 0;
  const { onConditionnalyAddRèglementLine } = règlementsProps;
  const toggleRèglementsInput = () => {
    onConditionnalyAddRèglementLine(0);
  };

  const linesIndexWithVentilations = facture.règlements.reduce(
    (result, r, idx) => ({
      ...result,
      [idx]: r.ventilations.length > 0,
    }),
    {} as { [key: number]: boolean }
  );

  const utils = trpc.useContext();
  const factureEditedCommitMutation = trpc.facture.commitEdited.useMutation({
    onSuccess: () => {
      setIsSubmitting(false);
      setShowAlertSuccess(true);
      factureProps.onCancelCurrentFacture(); //on remet à zéro après un commit réussi
      setTimeout(() => {
        setShowAlertSuccess(false);
      }, 3000);
      utils.facture.invalidate();
      utils.libellé.invalidate();
      setIsNotTouched();
      props.onDone();
    },
    onError: () => {
      setIsSubmitting(false);
      setShowAlertError(true);
      setTimeout(() => {
        setShowAlertError(false);
      }, 3000);
    },
  });

  const factureCreateMutation = trpc.facture.create.useMutation({
    onSuccess: () => {
      setIsSubmitting(false);
      setShowAlertSuccess(true);
      factureProps.onCancelCurrentFacture(); //on remet à zéro après un commit réussi
      setTimeout(() => {
        setShowAlertSuccess(false);
      }, 3000);
      utils.facture.invalidate();
      utils.libellé.invalidate();
      setIsNotTouched();
      props.onDone();
    },
    onError: () => {
      setIsSubmitting(false);
      setShowAlertError(true);
      setTimeout(() => {
        setShowAlertError(false);
      }, 3000);
    },
  });

  const factureDeleteMutation = trpc.facture.delete.useMutation({
    onSuccess: () => {
      setIsSubmitting(false);
      setShowAlertSuccess(true);
      factureProps.onCancelCurrentFacture(); //on remet à zéro après un commit réussi
      setTimeout(() => {
        setShowAlertSuccess(false);
      }, 3000);
      utils.facture.invalidate();
      utils.libellé.invalidate();
      setIsNotTouched();
      props.onDone();
    },
    onError: () => {
      setIsSubmitting(false);
      setShowAlertError(true);
      setTimeout(() => {
        setShowAlertError(false);
      }, 3000);
    },
  });

  const onCommitFacture = () => {
    setIsSubmitting(true);
    const factureCleaned = factureStateToFacture(facture);
    if (!factureValidate(factureCleaned)) {
      throw new Error("facture invalide dans commitFacture");
    }
    if (facture._id && facture.owner) {
      factureEditedCommitMutation.mutate({
        ...factureCleaned,
        _id: facture._id,
        owner: facture.owner,
      });
    } else {
      factureCreateMutation.mutate(factureCleaned);
    }
  };

  const onDeleteFacture = () => {
    const _id = facture._id;
    if (!_id) {
      throw new Error("facture._id est null dans onDeleteFacture");
    }
    setIsSubmitting(true);
    factureDeleteMutation.mutate(_id);
  };
  return (
    <div>
      <FactureInput
        {...factureProps}
        facture={facture}
        onOpenGeneralVentilations={onOpenGeneralVentilations}
        hasVentilations={facture.ventilations.length > 0}
        onToggleRèglements={toggleRèglementsInput}
        onCommitFacture={onCommitFacture}
        isSubmitting={isSubmitting}
        onCancelEdit={onCancelEditFacture}
        onDelete={onDeleteFacture}
      />
      {showAlertSuccess && (
        <div className="alert alert-success" role="alert">
          Enregistré avec succès
        </div>
      )}
      {showAlertError && (
        <div className="alert alert-danger" role="alert">
          Il y a eu un problème lors de l'enregistrement de la facture
        </div>
      )}
      {showRèglementsInput && (
        <FactureRèglementsComp
          {...règlementsProps}
          règlements={facture.règlements}
          onOpenVentilations={onOpenLineVentilations}
          linesIndexWithVentilations={linesIndexWithVentilations}
        />
      )}

      <Modal
        show={ventilationModalOpen}
        dialogClassName="modalWidthClass"
        fullscreen="sm-down"
      >
        <VentilationMainPanel
          ventilations={ventilations}
          montantCent={ventilationTotalTargetCent}
          totalActuelVentilationsCent={totalActuelVentilationsCent}
          onValueChange={onUpdateVentilation}
          onDeleteVentilation={onDeleteVentilation}
          onDeleteAllVentilations={handleDeleteAllVentilations}
          errors={ventilationsErrors}
          onCancelVentilations={handleCancelVentilations}
          acceptVentilations={handleAcceptVentilations}
          deleteAllEnabled={ventilations.length > 0}
          similarLinesIds={[]}
          onSetSimilarLinesIds={() => {}}
        />
      </Modal>
    </div>
  );
};

export default MainFactureInputPanel;
