import React from "react";
import { filterCompte } from "../processing/compteSearcher";
import useRecetteDepense from "../hooks/useRecetteDepense";
import { Ventilation3 } from "compta-shared";
import AutoComplete from "../../utils/components/AutoCompleteComp/AutoComplete2.tsx";
import FloatInput from "../../utils/components/FloatInput.tsx";

export type VentilationUpdateValueTypes = {
  compte: string;
  montantCent: number;
  pourcentageCent: number | null;
};
export type VentilationLineProps = Ventilation3 & {
  onValueChange: <K extends keyof VentilationUpdateValueTypes>(
    field: K,
    value: VentilationUpdateValueTypes[K]
  ) => void;
  onDeleteVentilation: () => void;
};

const VentilationLine = (props: VentilationLineProps) => {
  const { compte, montantCent, pourcentageCent } = props;
  const { onValueChange, onDeleteVentilation } = props;
  const [search, setSearch] = React.useState("");
  const candidates = filterCompte(search);
  const onCompteSearch = (value: string | null) => setSearch(value || "");
  const onSetCompte = (value: string) => onValueChange("compte", value);
  const { onRecetteChange, onDépenseChange, recetteCent, dépenseCent } =
    useRecetteDepense(montantCent, (value) =>
      onValueChange("montantCent", value)
    );
  return (
    <tr className="d-flex">
      <td className="col-3">
        <AutoComplete
          candidates={candidates}
          onSetSearch={onCompteSearch}
          onSetValue={onSetCompte}
          value={compte}
          tagsOn={false}
          acceptNewValues={false}
          isValid={compte !== ""}
        />
      </td>
      <td className="col-2">
        <div className="input-group">
          <FloatInput
            valueCent={pourcentageCent || null}
            onValueCentChange={(valueCent) =>
              onValueChange("pourcentageCent", valueCent)
            }
            placeholder="Pourcentage"
          />
          <span className="input-group-text">%</span>
        </div>
      </td>
      <td className="col-2">
        <FloatInput
          valueCent={dépenseCent}
          onValueCentChange={onDépenseChange}
          placeholder="Montant"
        />
      </td>
      <td className="col-2">
        <FloatInput
          valueCent={recetteCent}
          onValueCentChange={onRecetteChange}
          placeholder="Montant"
        />
      </td>
      <td className="col-1">
        <button
          type="button"
          className="btn btn-danger"
          onClick={onDeleteVentilation}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-trash"
            viewBox="0 0 16 16"
          >
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
            <path
              fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
            />
          </svg>
        </button>
      </td>
    </tr>
  );
};

export default VentilationLine;
