import React from "react";
import { filterCompte } from "../processing/compteSearcher";
import { FactureState, useFacture } from "../hooks/useFacture";
import useRecetteDepense from "../hooks/useRecetteDepense";
import modes from "../../main/consts/modes.ts";
import DateInput from "../../utils/components/DateInput.tsx";
import FloatInput from "../../utils/components/FloatInput.tsx";
import AutoComplete from "../../utils/components/AutoCompleteComp/AutoComplete2.tsx";
import ValidatedInput from "../../utils/components/ValidatedInput.tsx";
import { trpc } from "../../main/components/ProviderContainer.tsx";

type FactureInputProps = ReturnType<typeof useFacture>["factureProps"] & {
  facture: ReturnType<typeof useFacture>["facture"];
  onCommitFacture: () => void;
  onCancelEdit: () => void;
  onDelete: () => void;
  onOpenGeneralVentilations: () => void;
  onToggleRèglements: () => void;
  hasVentilations: boolean;
  factureErrors: string[];
  isSubmitting: boolean;
};

const FactureInput = (props: FactureInputProps) => {
  const {
    facture,
    onFactureLibelléChange,
    onFactureDateChange,
    onFactureCompteChange,
    onFactureModeChange,
    onFactureModeRefChange,
    onFactureMontantCentChange,
    onToggleRèglements,
  } = props;
  const { onCommitFacture, onCancelEdit, onDelete } = props;
  const { onOpenGeneralVentilations } = props;
  const { hasVentilations } = props;
  const hasRèglements = facture.règlements && facture.règlements.length > 0;
  const [modeSearch, setModeSearch] = React.useState("");
  const modeSearchReg = new RegExp(modeSearch, "i");
  const modeSearchCandidates = modeSearch
    ? modes.filter((mode) => modeSearchReg.test(mode))
    : [];

  const [compteSearch, setCompteSearch] = React.useState("");
  const compteSearchCandidates = filterCompte(compteSearch);

  const [searchLibellé, onSetSearchLibellé] = React.useState("");
  const { data: libellés } = trpc.libellé.getAll.useQuery();
  const searchReg = new RegExp(searchLibellé, "i");
  const libelléSearchCandidates =
    libellés && searchLibellé
      ? libellés.filter((libellé) => searchReg.test(libellé.libellé))
      : [];
  const handleLibelléChange = (libellé: string) => {
    const libelléObj = libellés?.find((l) => l.libellé === libellé);

    onFactureLibelléChange(libelléObj || libellé);
    onSetSearchLibellé("");
  };
  const { recetteCent, onRecetteChange, onDépenseChange, dépenseCent } =
    useRecetteDepense(facture.montantCent, onFactureMontantCentChange);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <span className="fs-4">
            {facture._id ? "Edition d'une facture" : "Nouvelle facture"}
          </span>
          {props.isTouched &&
            props.factureErrors.map((error) => (
              <span key={error + "key"} className="badge text-bg-danger m-2">
                {error}
              </span>
            ))}
        </div>
        <div className="card-body">
          <form>
            <div className="row">
              <div className="col-6 col-md-2">
                <label>Libellé</label>
                <AutoComplete
                  candidates={libelléSearchCandidates.map((l) => l.libellé)}
                  onSetSearch={(value) => onSetSearchLibellé(value || "")}
                  onSetValue={handleLibelléChange}
                  value={facture.libellé || ""}
                  tagsOn={false}
                  acceptNewValues={true}
                  isValid={!!facture.libellé}
                  invalidMessage="Un libellé doit être fourni"
                  isTouched={props.isTouched}
                />
              </div>
              <div className="col-5 col-md-2">
                <label>Date règlement</label>
                <DateInput
                  onDateChange={onFactureDateChange}
                  date={facture.date}
                  isValid={!!facture.date}
                  invalidMessage="Une date doit être fournie"
                  disabled={facture.règlements.length > 0}
                />
              </div>
              <div className="col-4 col-md-1">
                <label>Dépense</label>
                <FloatInput
                  valueCent={dépenseCent}
                  onValueCentChange={onDépenseChange}
                  disabled={facture.règlements.length > 0}
                />
              </div>
              <div className="col-4 col-md-1">
                <label>Recette</label>
                <FloatInput
                  valueCent={recetteCent}
                  onValueCentChange={onRecetteChange}
                  disabled={facture.règlements.length > 0}
                />
              </div>
              <div className="col-5 col-md-2">
                <label>Mode</label>
                <AutoComplete
                  candidates={modeSearchCandidates}
                  onSetSearch={(value) => setModeSearch(value || "")}
                  onSetValue={(value) =>
                    onFactureModeChange((value as FactureState["mode"]) || null)
                  }
                  value={facture.mode || ""}
                  isValid={!!facture.mode}
                  invalidMessage={"Un mode doit être fourni"}
                  tagsOn={false}
                  acceptNewValues={false}
                  isTouched={props.isTouched}
                />
              </div>
              <div className="col-4 col-md-1">
                <label>Ref</label>
                <ValidatedInput
                  invalidMessage={"Une référence doit être fournie"}
                  isValid={!!facture.modeRef}
                  value={facture.modeRef || ""}
                  onChange={onFactureModeRefChange}
                  placeholder={"Référence"}
                />
              </div>

              <div className="col-8 col-md-2">
                <label>Compte</label>
                {hasVentilations ? (
                  <input type="text" className="form-control" disabled />
                ) : (
                  <AutoComplete
                    candidates={compteSearchCandidates}
                    onSetSearch={(value) => setCompteSearch(value || "")}
                    onSetValue={onFactureCompteChange}
                    value={facture.compte || ""}
                    tagsOn={false}
                    acceptNewValues={false}
                    isValid={!!facture.compte && !hasVentilations}
                    invalidMessage={"Un compte doit être fourni"}
                    isTouched={props.isTouched}
                  />
                )}
              </div>
            </div>
            <div className="row pt-2">
              <div className="col-2">
                <button
                  type="button"
                  className={
                    "btn  mb-2" +
                    " " +
                    (hasRèglements ? "btn-success" : "btn-primary")
                  }
                  onClick={onToggleRèglements}
                >
                  Règlements multiples
                </button>
              </div>
              {facture.montantCent !== 0 ? (
                <div className="col-2">
                  <button
                    type="button"
                    className={
                      "btn mb-2 " +
                      (hasVentilations ? "btn-success" : "btn-primary")
                    }
                    onClick={onOpenGeneralVentilations}
                  >
                    Ventilation générale
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row pt-2">
              <div className="col-1">
                <button
                  type="button"
                  className="btn btn-primary mb-2"
                  onClick={onCommitFacture}
                  disabled={
                    props.factureErrors.length > 0 || props.isSubmitting
                  }
                >
                  Enregistrer
                </button>
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-warning mb-2"
                  onClick={onCancelEdit}
                >
                  Abandonner Modifications
                </button>
              </div>
              {facture._id ? (
                <div className={"col-1"}>
                  <button
                    type="button"
                    className="btn btn-danger mb-2"
                    onClick={onDelete}
                  >
                    Supprimer
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FactureInput;
