import FactureRèglementLine from "./FactureRèglementLine";
import { integerCentToFrenchFinanString } from "vincent-utils";
import type { ExistingFacture3 } from "compta-shared";
import DateDisplayerComp from "../../utils/components/DateDisplayerComp.tsx";
import RapprochéBadge from "./RapprochéBadge.tsx";

interface FactureCompProps {
  facture: ExistingFacture3;
  onFactureClicked: (factureId:string) => void;
}

const FactureComp = (props: FactureCompProps) => {
  const { facture, onFactureClicked } = props;
  const { libellé, date, banqueId, mode } = facture;
  const { règlements } = facture;
  const reglComps = règlements
    ? règlements.map((regl, idx) => (
        <FactureRèglementLine
          key={idx + "reglComps"}
          montant={integerCentToFrenchFinanString(regl.montantCent)}
          date={regl.date}
          banqueId={regl.banqueId || ""}
        />
      ))
    : null;
  return (
    <div
      onClick={()=>onFactureClicked(facture._id)}
      style={{ cursor: "pointer" }}
      className="row"
    >
      <div className="col-2">
        {date ? <DateDisplayerComp date={date} /> : ""}
      </div>
      <div className="col-2">{libellé} </div>
      <div className="col-1">
        {integerCentToFrenchFinanString(facture.montantCent)}
      </div>
      <div className="col-2">{mode || ""} </div>
      <div className="col-2">{facture.modeRef || ""} </div>
      <div className="col-1">
        {règlements.length === 0 ? <RapprochéBadge isRapproché={!!banqueId} /> : ""}
      </div>
      <div className="col-1">{reglComps}</div>
    </div>
  );
};

export default FactureComp;
