import { FactureState } from "../hooks/useFacture";
import { isEmptyRèglementLine } from "../processing/sanitizeFacture2";
import applyPourcentageVentilations from "../../ventilations/processors/applyPourcentageVentilations";
import _ from "underts";

const isRèglementVentilationCompatibleWithMainVentilation = (
  facture: FactureState
) => {
  const règlements = facture.règlements.filter((r) => !isEmptyRèglementLine(r));
  if (règlements.some((r) => r.ventilations.some((v) => !v.pourcentageCent))) {
    return false;
  }
  if (facture.ventilations.some((v) => !v.pourcentageCent)) {
    return false;
  }
  const expected = règlements
    .map((r) =>
      applyPourcentageVentilations(facture.ventilations, r.montantCent)
    )
    .flat(1);
  const actual = règlements.map((r) => r.ventilations).flat(1);
  return _.isEqual(expected, actual);
};

const factureStateValidator = (facture: FactureState) => {
  const errors: string[] = [];
  if (!facture.libellé) {
    errors.push("Le libellé est obligatoire");
  }
  if (
    !facture.date &&
    (facture.règlements.filter((r) => !isEmptyRèglementLine(r)).length === 0 ||
      facture.règlements
        .filter((r) => !isEmptyRèglementLine(r))
        .some((r) => !r.date))
  ) {
    errors.push(
      "Soit la facture, soit tous les règlements doivent avoir une date"
    );
  }
  if (!facture.montantCent) {
    errors.push("Le montant est obligatoire");
  }

  if (!facture.mode) {
    errors.push("Le mode est obligatoire");
  }

  if (
    !facture.compte &&
    facture.ventilations.length === 0 &&
    (facture.règlements.length === 0 ||
      facture.règlements
        .filter((r) => !isEmptyRèglementLine(r))
        .some((r) => r.ventilations.length === 0))
  ) {
    errors.push("Le compte est obligatoire si pas de ventilations");
  }

  if (!facture.modeRef) {
    errors.push("La référence de la facture est obligatoire");
  }

  if (facture.règlements.length > 0) {
    errors.push(...règlementsStateValidator(facture));
  }
  if (
    facture.règlements.length > 0 &&
    facture.ventilations.some((v) => !v.pourcentageCent)
  ) {
    errors.push(
      "Comme il y a des règlements multiples la ventilation générale doit avoir des pourcentages"
    );
  }

  if (
    facture.ventilations.length > 0 &&
    facture.règlements.length > 0 &&
    facture.règlements.some((r) => r.ventilations.length > 0)
  ) {
    if (!isRèglementVentilationCompatibleWithMainVentilation(facture)) {
      errors.push(
        "Les règlements individuels doivent avoir les mêmes ventilations que la ventilation générale"
      );
    }
  }

  return errors;
};

const règlementsStateValidator = (factureState: FactureState) => {
  const règlements = factureState.règlements;
  const errors: string[] = [];
  if (règlements.length > 0) {
    const total = règlements.reduce((sum, règlement) => {
      return sum + (règlement.montantCent || 0);
    }, 0);
    if (total !== factureState.montantCent) {
      errors.push(
        "Le total des règlements ne correspond pas au montant de la facture"
      );
    }
  }
  return errors;
};

export default factureStateValidator;
