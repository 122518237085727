import React from "react";
import _ from "underts";
import { trpc } from "../main/components/ProviderContainer.tsx";
import classNames from "classnames";


type TaskResult = {
  result: "success"
} | {
  result: "error";
  message: string;
} | {
  result: "pending";
}

const LaunchTasks = () => {
  const [hasTasksRunning, setHasTasksRunning] = React.useState(false);
  const [rebuildGrandLivreResult, setRebuildGrandLivreResult] =
    React.useState<{
      [year: number]: TaskResult;
    }>({});
  const [rapprocheBordereauxResult, setRapprocheBordereauxResult] =
    React.useState<TaskResult|null>(null);
  const [rapprocheFacturesResult, setRapprocheFacturesResult] =
    React.useState<TaskResult|null>(null);
  const currentYear = new Date().getFullYear();
  const previousYears = _.range(5, -1, -1).map((i) => currentYear - i);

  const rebuildGrandLivreMutation = trpc.grandLivre.rebuild.useMutation({
    onMutate: (year) => {
      setRebuildGrandLivreResult((prev) => ({
        ...prev,
        [year]: {
          result: "pending",
        },
      }));
    },
    onSuccess: (_data, year) => {
      setRebuildGrandLivreResult((prev) => ({
        ...prev,
        [year]: {
          result: "success",
    }}))
  },
    onSettled: () => {
      setHasTasksRunning(false);
    },
    onError: (error, year) => {
      setRebuildGrandLivreResult((prev) => ({
        ...prev,
        [year]: {
          result: "error",
          message: error.message,
        },
      }));
    }
  });
  const rapprocheBordereauxMutation = trpc.rapprochement.bordereaux.useMutation(
    {
      onMutate: () => {
        setRapprocheBordereauxResult({
          result: "pending",
        });
      },
      onSuccess: () => {
        setRapprocheBordereauxResult({
          result: "success",
        });
      },
      onError: (error) => {
        setRapprocheBordereauxResult({
          result: "error",
          message: error.message,
        });
      },
      onSettled: () => {
        setHasTasksRunning(false);
      },
    },
  );
  const rapprocheFacturesMutation = trpc.rapprochement.factures.useMutation({
    onMutate: () => {
      setRapprocheFacturesResult({
        result: "pending",
      });
    },
    onSuccess: () => {
      setRapprocheFacturesResult({
        result: "success",
      });
    },
    onError: (error) => {
      setRapprocheFacturesResult({
        result: "error",
        message: error.message,
      });
    },
    onSettled: () => {
      setHasTasksRunning(false);
    },
  });
  const handleRebuildGrandLivre = async (year: number) => {
    setHasTasksRunning(true);
    rebuildGrandLivreMutation.mutate(year);
  };
  const handleRapprocheBordereaux = async () => {
    setHasTasksRunning(true);
    rapprocheBordereauxMutation.mutate();
  };
  const handleRapprocheFactures = async () => {
    setHasTasksRunning(true);
    rapprocheFacturesMutation.mutate();
  };
  return (
    <div className="card">
      <div className="card-header">
        <h3 className="card-title">Tâches</h3>
      </div>
      <div className="card-body">
        {previousYears.map((year) => (
          <div className="row" key={"button" + year}>
            <button
              className={classNames("btn", {
                "btn-primary": !rebuildGrandLivreResult[year],
                "btn-success":
                  rebuildGrandLivreResult[year]?.result === "success",
                "btn-danger":
                  rebuildGrandLivreResult[year]?.result === "error",
              })}
              onClick={() => handleRebuildGrandLivre(year)}
              disabled={hasTasksRunning}
            >
              Rebuild Grand Livre {year}
            </button>
          </div>
        ))}
        <div className="row" key={"rapprocheBordereauKey"}>
          <button
            className={classNames("btn", {
              "btn-primary": !rapprocheBordereauxResult,
              "btn-success":
                rapprocheBordereauxResult?.result === "success",
              "btn-danger":
                rapprocheBordereauxResult?.result === "error", 
            })}
            onClick={() => handleRapprocheBordereaux()}
            disabled={hasTasksRunning}
          >
            Rapproche Bordereaux
          </button>
        </div>
        <div className="row" key={"rapprocheFacturesKey"}>
          <button
            className={classNames("btn", {
              "btn-primary": !rapprocheFacturesResult,
              "btn-success":
                rapprocheFacturesResult?.result === "success",
              "btn-danger":
                rapprocheFacturesResult?.result === "error",
            })}
            onClick={() => handleRapprocheFactures()}
            disabled={hasTasksRunning}
          >
            Rapproche Factures
          </button>
        </div>
      </div>
    </div>
  );
};

export default LaunchTasks;
