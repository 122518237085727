import { Libelle } from "compta-shared";
import AutoComplete from "../utils/components/AutoCompleteComp/AutoComplete2";
import React from "react";
import { filterCompte } from "../factures/processing/compteSearcher";
import { trpc } from "../main/components/ProviderContainer";

export default function LibelleLine(libelle: Libelle) {
  const [compteSearch, setCompteSearch] = React.useState("");
  const [compte, setCompte] = React.useState(libelle.compte);
  const compteSearchCandidates = filterCompte(compteSearch);
  const utils = trpc.useUtils();
  const updateCompteMutation = trpc.libellé.set.useMutation({
    onSuccess: () => {
      utils.libellé.invalidate();
    },
    onError: () => {
      alert("Erreur de mise à jour");
    },
  });
  const isTouched = compte !== libelle.compte;
  const isValid = !!compte;
  return (
    <div className="row align-items-center">
      <div className="col-3">{libelle.libellé}</div>
      <div className="col-3">
        <AutoComplete
          candidates={compteSearchCandidates}
          onSetSearch={(value) => setCompteSearch(value || "")}
          onSetValue={setCompte}
          value={compte}
          tagsOn={false}
          acceptNewValues={false}
          isValid={isValid}
          invalidMessage={"Un compte doit être fourni"}
          isTouched={isTouched}
        />
      </div>
      <div className="col-2">
        <button
          className={"btn" + " " + (isTouched && isValid ? "btn-primary" : "")}
          onClick={() =>
            updateCompteMutation.mutate({ compte, libellé: libelle.libellé })
          }
          disabled={!isTouched || !isValid}
        >
          Mettre à jour
        </button>
      </div>
    </div>
  );
}
