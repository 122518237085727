import { Ventilation3 } from "compta-shared";
import VentilationLine, {
  VentilationUpdateValueTypes,
} from "./VentilationLine.tsx";

export interface VentilationsLinesProps {
  ventilations: Ventilation3[];
  onValueChange: <K extends keyof VentilationUpdateValueTypes>(
    idx: number,
    field: K,
    value: VentilationUpdateValueTypes[K]
  ) => void;
  onDeleteVentilation: (idx: number) => void;
}

const VentilationsLines = (props: VentilationsLinesProps) => {
  const { ventilations } = props;
  const { onValueChange, onDeleteVentilation } = props;
  const ventilationsComps =
    ventilations &&
    ventilations.map((ventilation, idx) => (
      <VentilationLine
        {...ventilation}
        onValueChange={onValueChange.bind(null, idx)}
        onDeleteVentilation={onDeleteVentilation.bind(null, idx)}
        key={"ventilationLine" + idx}
      />
    ));
  return (
    <table className="table">
      <thead>
        <tr className="d-flex">
          <th className="col-3">
            Compte<span style={{ color: "red" }}>*</span>
          </th>
          <th className="col-2">Pourcentage du total(facultatif)</th>
          <th className="col-2">Sortie (dépense)</th>
          <th className="col-2">Entrée (recette)</th>
          <th className="col-1">Supprimer</th>
        </tr>
      </thead>
      <tbody>{ventilationsComps}</tbody>
    </table>
  );
};

export default VentilationsLines;
