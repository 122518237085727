import VentilationMainPanel from "../../factures/components/VentilationMainPanel";
import { Modal } from "react-bootstrap";
import { useVentilations } from "../../ventilations/hooks/useVentilations";
import { ventilationsValidator } from "../../ventilations/validators/ventilationsValidator";
import { trpc } from "../../main/components/ProviderContainer.tsx";
import { useState } from "react";
import _ from "underts";

export type VentilationsState = ReturnType<
  typeof useVentilations
>["ventilationsState"];

interface VentilationsContainerProps {
  banqueLineId: string;
  actionId: string | null;
  similarLinesIds: string[];
  onCancelVentilations: () => void;
}

function defaultFillPourcentage<K extends { pourcentageCent?: number }>(
  obj: K
) {
  return _.convertOptionnalKeysToNullable(obj, ["pourcentageCent"]);
}

function defaultFillMontant<K extends { montantCent?: number }>(obj: K) {
  if (obj.montantCent === undefined) {
    return { ...obj, montantCent: 0 };
  }
  return obj as K & { montantCent: number };
}

function nullPourcentageToUndefined<
  K extends { pourcentageCent: number | null },
>(obj: K) {
  return _.convertNullableKeysToOptionnal(obj);
}

const VentilationsContainer = (props: VentilationsContainerProps) => {
  const [similarLinesIds, setSimilarLinesIds] = useState<string[]>([]);
  console.log("similarLinesIds", similarLinesIds);

  const { banqueLineId, onCancelVentilations } = props;
  const { data: action } = trpc.action.getById.useQuery(
    props.actionId as string,
    { enabled: !!props.actionId }
  );
  const { data: line } = trpc.banque.getBanqueLine.useQuery(banqueLineId);
  console.log("line in VentilationsContainer", line);
  console.log("banqueLineId in VentilationsContainer", banqueLineId);
  console.log("action", action);
  console.log("props.actionId", props.actionId);

  const montantCent = line ? line.montantCent : 0;
  const {
    ventilationsState,
    onDeleteVentilation,
    onUpdateVentilation,
    totalActuelVentilationsCent,
    serializedVentilations,
  } = useVentilations(
    action &&
      "ventilations" in action &&
      action.ventilations &&
      action.ventilations.every((v) => !!v.montantCent)
      ? action.ventilations.map(defaultFillPourcentage).map(defaultFillMontant)
      : [],
    montantCent
  );
  const handleCancelVentilations = () => {
    // setSelectedActionId(null);
    onCancelVentilations();
  };
  const util = trpc.useContext();
  const commitVentilationsMutation = trpc.action.commitSimilarLines.useMutation(
    {
      onSuccess: () => {
        util.banque.invalidate();
        onCancelVentilations();
      },
    }
  );
  const onCommitVentilations = async () => {
    commitVentilationsMutation.mutate({
      ventilations: serializedVentilations.map(nullPourcentageToUndefined),
      similarLinesIds: [
        banqueLineId,
        ...props.similarLinesIds,
        ...similarLinesIds,
      ],
      oldActionsIds: action ? [action._id] : [],
    });
  };

  const deleteVentilationsMutation = trpc.action.delete.useMutation({
    onSuccess: () => {
      util.action.invalidate();
      onCancelVentilations();
    },
  });
  const handleDeleteVentilations = async () => {
    if (props.actionId) {
      deleteVentilationsMutation.mutate([props.actionId]);
    }
  };

  const errors = ventilationsValidator(
    ventilationsState,
    props.similarLinesIds.length > 0
  );
  return (
    <Modal show={!!line} dialogClassName="modalWidthClass" fullscreen="sm-down">
      <VentilationMainPanel
        line={line!}
        ventilations={ventilationsState.ventilations}
        errors={errors}
        onDeleteVentilation={onDeleteVentilation}
        onValueChange={onUpdateVentilation}
        acceptVentilations={onCommitVentilations}
        montantCent={ventilationsState.totalCent}
        totalActuelVentilationsCent={totalActuelVentilationsCent}
        onCancelVentilations={handleCancelVentilations}
        deleteAllEnabled={!!props.actionId}
        onDeleteAllVentilations={handleDeleteVentilations}
        similarLinesIds={similarLinesIds}
        onSetSimilarLinesIds={setSimilarLinesIds}
      />
    </Modal>
  );
};

export default VentilationsContainer;
