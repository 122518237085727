import FloatCentDisplayer from "./FloatCentDisplayer";
import type { BanqueLine2 } from "compta-shared";
import DateDisplayerComp from "../../utils/components/DateDisplayerComp.tsx";

type DepenseLineProps = {
  selected: boolean;
  onVentiler: (id: string) => void;
} & BanqueLine2;

const DepenseLine = (props: DepenseLineProps) => {
  const montantCent = props.montantCent;
  const { selected, date, libelle, onVentiler, _id } = props;
  return (
    <li
      className={
        "row d-flex list-group-item" + " " + (selected ? "active" : "")
      }
    >
      {date ? (
        <div className="col-2">
          <DateDisplayerComp date={date} />{" "}
        </div>
      ) : (
        ""
      )}
      <div className="col-3"> {libelle}</div>
      <div className="col-1">
        {" "}
        <FloatCentDisplayer cent={montantCent} />
      </div>
      <div className="col-1">
        <span
          className={"badge bg-secondary"}
          onClick={onVentiler.bind(null, _id)}
          color="secondary"
          style={{ cursor: "pointer" }}
        >
          Ventiler
        </span>
      </div>
    </li>
  );
};

export default DepenseLine;
