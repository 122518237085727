import { trpc } from "../../main/components/ProviderContainer";
import FactureComp from "../components/FactureComp";
import DepenseLine from "../components/DepenseLine";
import _ from "underts";
import { useLocation, useSearchParams } from "wouter";

export function Examiner(props: { year: number }) {
  const [, setLocation] = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const lookup = searchParams.get("lookup") || "";
  const setLookup = (value: string) => {
    setSearchParams({ lookup: value });
  }
  const factures = trpc.facture.getByYear.useQuery(props.year).data || [];
  console.log("factures", factures);
  const { data: unjustifiedDepenses } =
    trpc.banque.getUnjustifiedDepenses.useQuery();
  const filteredFactures =
    lookup.length > 1
      ? factures.filter((facture) =>
          facture.libellé.toLowerCase().includes(lookup.toLowerCase())
        )
      : [];
  const sortedFactures = _.sortBy(
    filteredFactures,
    (facture) =>
      facture.date?.getTime() || facture.règlements[0]?.date?.getTime() || 0
  );
  const filteredDepenses =
    lookup.length > 1 && unjustifiedDepenses
      ? unjustifiedDepenses.filter(
          (depense) =>
            depense.libelle.toLowerCase().includes(lookup.toLowerCase()) &&
            depense.date.getFullYear() === props.year
        )
      : [];
  const sortedDepenses = _.sortBy(filteredDepenses, (depense) =>
    depense.date.getTime()
  );
  const onFactureClicked = (factureId: string) => {
    setLocation("~/factures/factureInput?factureId=" + factureId, {
      state: "back",
    });
  };
  return (
    <div>
      <div>Date examinée: {props.year}</div>
      <input
        type="text"
        value={lookup}
        onChange={(e) => setLookup(e.target.value)}
      />
      <div className="d-flex">
        <div className="col">
          {sortedFactures.map((facture) => (
            <FactureComp facture={facture} onFactureClicked={onFactureClicked} />
          ))}
        </div>
        <div className="col">
          {sortedDepenses.map((banqueLine) => (
            <DepenseLine
              {...banqueLine}
              selected={false}
              onVentiler={() => {}}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
