import React, { useEffect, useState } from "react";
import type { WithPartial } from "underts";
import luhn from "fast-luhn";
import { checkers } from "vincent-utils";
import _ from "underts";
import { trpc } from "../main/components/ProviderContainer.tsx";
import FloatInput from "../utils/components/FloatInput.tsx";
import { Settings } from "compta-shared";
import ValidatedInput from "../utils/components/ValidatedInput.tsx";

export type SettingsState = WithPartial<Settings, "_id" | "owner">;

const defaultSettings: SettingsState = {
  siren: "",
  name: "",
  years: {},
};

interface YearSettingsPanelProps {
  yearSettings: SettingsState["years"][string];
  onValueCentChange: (
    type: "banque" | "caisse",
    valueCent: number | null
  ) => void;
  onSetAnouveauxAuto: (value: boolean) => void;
  onSetTicketRestauPourcentageCent: (value: number | null) => void;
}

const YearSettingsPanel = (props: YearSettingsPanelProps) => {
  const handleSetAnouveauxAuto = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    props.onSetAnouveauxAuto(event.target.checked);
  };
  return (
    <>
      <div className="card mt-2">
        <div className="row mt-2">
          <div className="m-2">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckAuto"
                onChange={handleSetAnouveauxAuto}
                checked={props.yearSettings.anouveau.type === "auto"}
              />
              <label className="form-check-label" htmlFor="flexSwitchCheckAuto">
                A nouveaux automatiques pour cette année
              </label>
            </div>
          </div>
        </div>

        {props.yearSettings.anouveau.type !== "auto" ? (
          <div className="row">
            <div className="col-md-6">
              <div className="m-2">
                <label className="form-label">Balance banque</label>
                <FloatInput
                  valueCent={props.yearSettings.anouveau.banqueCent || 0}
                  onValueCentChange={(value) =>
                    props.onValueCentChange("banque", value)
                  }
                />
              </div>
              <div className="m-2">
                <label className="form-label">Balance caisse</label>
                <FloatInput
                  valueCent={props.yearSettings.anouveau.caisseCent || 0}
                  onValueCentChange={(value) =>
                    props.onValueCentChange("caisse", value)
                  }
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className="card mt-2">
        <div className="row mt-2 p-3 align-items-center">
          <div className="col-auto">
            <label className="form-label">
              Pourcentage prise en charge employeur Tickets Restau
            </label>
          </div>
          <div className="col-auto">
            <FloatInput
              valueCent={props.yearSettings.ticketRestauPourcentageCent}
              onValueCentChange={props.onSetTicketRestauPourcentageCent}
              minCent={0}
              maxCent={10000}
            />
          </div>
        </div>
      </div>
    </>
  );
};

const useSettingsState = () => {
  const { data: settings } = trpc.setting.get.useQuery();
  const [settingsState, setSettingsState] = React.useState<SettingsState>(
    settings || defaultSettings
  );
  const [isDirty, setIsDirty] = useState(false);
  useEffect(() => {
    setSettingsState(
      settings ? { ...defaultSettings, ...settings } : defaultSettings
    );
    setIsDirty(false);
  }, [JSON.stringify(settings)]);
  const onToggleAutoAnouveauxStatus = (year: number) => {
    setIsDirty(true);
    setSettingsState((state) => {
      if (state.years[year].anouveau.type === "auto") {
        return {
          ...state,
          years: {
            ...state.years,
            [year]: {
              anouveau: { type: "manual", banqueCent: 0, caisseCent: 0 },
            },
          },
        };
      } else {
        return {
          ...state,
          years: {
            ...state.years,
            [year]: {
              anouveau: { type: "auto" },
            },
          },
        };
      }
    });
  };
  const onAddYear = (year: number) => {
    setIsDirty(true);
    setSettingsState((state) => ({
      ...state,
      ...(state.years[year]
        ? {}
        : {
            years: {
              ...state.years,
              [year]: {
                anouveau: { type: "auto" },
                ticketRestauPourcentageCent: 5000,
              },
            },
          }),
    }));
  };
  const onSetSiren = (siren: string) => {
    setIsDirty(true);
    setSettingsState((state) => ({ ...state, siren }));
  };
  const onSetName = (name: string) => {
    setIsDirty(true);
    setSettingsState((state) => ({ ...state, name }));
  };
  const onSetTicketRestauPourcentageCent = (
    year: number,
    value: number | null
  ) => {
    setIsDirty(true);
    setSettingsState((state) => {
      return {
        ...state,
        years: {
          ...state.years,
          [year]: {
            ...state.years[year],
            ticketRestauPourcentageCent: value || 0,
          },
        },
      };
    });
  };
  const onSetAnouveauValue = (
    year: number,
    type: "banque" | "caisse",
    valueCent: number | null
  ) => {
    setIsDirty(true);
    setSettingsState((state) => {
      const yearSettings = state.years[year];
      if (yearSettings.anouveau.type === "auto") {
        return state;
      } else {
        return {
          ...state,
          years: {
            ...state.years,
            [year]: {
              ...yearSettings,
              anouveau: {
                ...yearSettings.anouveau,
                [type + "Cent"]: valueCent,
              },
            },
          },
        };
      }
    });
  };

  const sirenIsValid =
    luhn(settingsState.siren) && settingsState.siren.length === 9;
  const yearSettingsAreValid = Object.values(settingsState.years).every(
    (yearSettings) => {
      if (yearSettings.anouveau.type === "auto") {
        return true;
      } else {
        return (
          yearSettings.anouveau.banqueCent !== null &&
          yearSettings.anouveau.caisseCent !== null
        );
      }
    }
  );
  const hasErrors = !sirenIsValid || !yearSettingsAreValid;

  return {
    settingsState,
    isDirty,
    sirenIsValid,
    hasErrors,
    onToggleAutoAnouveauxStatus,
    onAddYear,
    onSetSiren,
    onSetAnouveauValue,
    onSetName,
    onSetTicketRestauPourcentageCent,
  };
};

const SettingsPanel = () => {
  const {
    settingsState,
    isDirty,
    sirenIsValid,
    hasErrors,
    onToggleAutoAnouveauxStatus,
    onAddYear,
    onSetSiren,
    onSetName,
    onSetAnouveauValue,
    onSetTicketRestauPourcentageCent,
  } = useSettingsState();
  console.log("settingsState", settingsState);

  const [tempYearAddValue, setTempYearAddValue] = React.useState<string>("");

  const yearInputIsValid = checkers.isValidYearString(tempYearAddValue);

  const saveEnabled = !hasErrors && isDirty;

  const [showAlertSuccess, setShowAlertSuccess] = React.useState(false);
  const [showAlertError, setShowAlertError] = React.useState(false);

  const [yearSelected, setYearSelected] = React.useState<number | null>(null);

  useEffect(() => {
    if (yearSelected === null) {
      const years = Object.keys(settingsState.years).map((y) => parseInt(y));
      if (years.length > 0) {
        setYearSelected(_.last(years)!);
      }
    }
  }, [JSON.stringify(settingsState)]);
  const handleYearSelected = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setYearSelected(event.target.value ? parseInt(event.target.value) : null);
  };
  const handleSirenChange = (value: string) => {
    onSetSiren(value);
  };

  const handleNameChange = (value: string) => {
    onSetName(value);
  };

  const handleYearAdd = () => {
    const year = parseInt(tempYearAddValue);
    setTempYearAddValue("");
    onAddYear(year);
    setYearSelected(year);
  };

  const handleSetAnouveauxAuto = () => {
    onToggleAutoAnouveauxStatus(yearSelected!);
  };

  const yearChoices = Object.keys(settingsState.years).map((y) => parseInt(y));

  const utils = trpc.useContext();

  const commitSettingsMutation = trpc.setting.commit.useMutation({
    onSuccess: () => {
      utils.setting.invalidate();
      setShowAlertSuccess(true);
      setTimeout(() => setShowAlertSuccess(false), 3000);
    },
    onError: () => {
      setShowAlertError(true);
      setTimeout(() => setShowAlertError(false), 3000);
    },
  });
  const handleSettingsSave = () => {
    commitSettingsMutation.mutate(settingsState);
  };

  const button = (
    <button
      className="btn btn-primary"
      disabled={!saveEnabled}
      onClick={handleSettingsSave}
    >
      Enregistrer
    </button>
  );

  return (
    <>
      <div className="card col-md-6">
        <div className="card-header">
          <h3 className="card-title pt-2">Paramètres Généraux</h3>
        </div>
        <div className="card-body">
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">SIREN</label>
                <ValidatedInput
                  value={settingsState.siren}
                  onChange={handleSirenChange}
                  placeholder="numéro de SIREN"
                  isValid={sirenIsValid}
                  invalidMessage="siren non valide"
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label">Nom complet</label>
                <ValidatedInput
                  value={settingsState.name}
                  onChange={handleNameChange}
                  placeholder="nom complet"
                  isValid={!!settingsState.name}
                  invalidMessage="nom manquant"
                />
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-1">{button}</div>
          </div>
          {showAlertSuccess && (
            <div className="alert alert-success mt-2" role="alert">
              Enregistré avec succès
            </div>
          )}
          {showAlertError && (
            <div className="alert alert-danger mt-2" role="alert">
              Il y a eu un problème lors de l'enregistrement des paramètres
            </div>
          )}
        </div>
      </div>
      <div className="card col-md-6 mt-2">
        <div className="card-header">
          <h3 className="card-title pt-2">Paramètres des exercices</h3>
        </div>
        <div className="card-body">
          <div className="row">
            <label className="form-label">Ajouter une année</label>
          </div>
          <div className="row">
            <div className="col">
              <ValidatedInput
                value={tempYearAddValue}
                onChange={setTempYearAddValue}
                placeholder="nouvelle année d'exercice"
                isValid={yearInputIsValid}
                invalidMessage="année non valide"
              />
            </div>
            <div className="col">
              <button
                className="btn btn-primary"
                disabled={!yearInputIsValid}
                onClick={handleYearAdd}
              >
                Ajouter
              </button>
            </div>
          </div>
          {yearChoices.length > 0 ? (
            <>
              <div className="row mt-2">
                <div className="col-md-2">
                  <select
                    className="form-select"
                    value={yearSelected || ""}
                    onChange={handleYearSelected}
                  >
                    {yearChoices.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div>
                {yearSelected !== null ? (
                  <YearSettingsPanel
                    yearSettings={settingsState.years[yearSelected.toString()]}
                    onValueCentChange={(type, value) =>
                      onSetAnouveauValue(yearSelected, type, value)
                    }
                    onSetAnouveauxAuto={handleSetAnouveauxAuto}
                    onSetTicketRestauPourcentageCent={(value) =>
                      onSetTicketRestauPourcentageCent(yearSelected, value)
                    }
                  />
                ) : null}
              </div>
            </>
          ) : null}
          <div className="row mt-2">
            <div className={"col-md-1"}>{button}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsPanel;
