import { NewFacture3 } from "compta-shared";
import { FactureState } from "../hooks/useFacture";
const factureStateToFacture = (factureState: FactureState) => {
  if (!factureState.mode) {
    throw new Error("factureState.mode is null in factureStateToFacture");
  }
  return {
    modeRef: factureState.modeRef,
    mode: factureState.mode,
    date: factureState.date || null,
    libellé: factureState.libellé,
    montantCent: factureState.montantCent,
    compte: factureState.compte || null,
    règlements: règlementsStateToRèglements(factureState.règlements),

    ventilations: ventilationsStateToVentilations(factureState.ventilations),
    docId: factureState.docId,
    validationDate: factureState.validationDate || null,
    banqueId: factureState.banqueId || null,
  } satisfies NewFacture3;
};

const règlementsStateToRèglements = (
  règlements: FactureState["règlements"]
) => {
  return règlements
    .filter((r) => !!r.date)
    .filter((r) => !!r.montantCent)
    .map((r) => {
      return {
        date: r.date!,
        montantCent: r.montantCent,

        ventilations: ventilationsStateToVentilations(r.ventilations),
        banqueId: r.banqueId,
      };
    });
};

const ventilationsStateToVentilations = (
  ventilations: FactureState["ventilations"]
) => {
  return ventilations.map((v) => {
    return {
      compte: v.compte,
      montantCent: v.montantCent,
      pourcentageCent: v.pourcentageCent,
    };
  });
};

export default factureStateToFacture;
