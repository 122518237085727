import FacturesInputContainer from "../containers/FacturesInputContainer";
import AllFacturesContainer from "../containers/AllFacturesContainer";
import UnjustifiedLinesContainer from "../containers/UnjustifiedDepensesContainer";
import { Route, Switch, useLocation } from "wouter";
import { Examiner } from "../containers/Examiner";

interface FacturesPanelSelectorProps {
  defaultYear: number;
}

const FacturesPanelSelector = (props: FacturesPanelSelectorProps) => {
  const [location, setLocation] = useLocation();
  console.log("location in FacturesPanelSelector", location);

  return (
    <div>
      <div className="pb-2">
        <div className="btn-group">
          <button
            type="button"
            className={
              "btn " +
              (location === "/factureInput" ? " btn-primary" : "btn-secondary")
            }
            onClick={() => setLocation("/factureInput")}
          >
            Input Facture
          </button>
          <button
            type="button"
            className={
              "btn " +
              (location.includes("/allFactures")
                ? " btn-primary"
                : "btn-secondary")
            }
            onClick={() => setLocation("/allFactures")}
          >
            Toutes les factures
          </button>
          <button
            type="button"
            className={
              "btn " +
              (location.includes("/allNonJustifiedDepenses")
                ? " btn-primary"
                : "btn-secondary")
            }
            onClick={() => setLocation("/allNonJustifiedDepenses")}
          >
            Dépenses non justifiées
          </button>
          <button
            type="button"
            className={
              "btn " +
              (location.includes("/allNonJustifiedEntrees")
                ? " btn-primary"
                : "btn-secondary")
            }
            onClick={() => setLocation("/allNonJustifiedEntrees")}
          >
            Entrées non justifiées
          </button>
          <button
            type="button"
            className={
              "btn " +
              (location.includes("/examiner")
                ? " btn-primary"
                : "btn-secondary")
            }
            onClick={() => setLocation("/examiner")}
          >
            Examiner
          </button>
        </div>
      </div>
      <div className="row">
        <Switch>
          <Route path="/factureInput" component={FacturesInputContainer} />
          <Route path="/allFactures" nest>
            <AllFacturesContainer defaultYear={props.defaultYear} />
          </Route>
          <Route path="/allNonJustifiedDepenses" nest>
            <UnjustifiedLinesContainer
              mode="depenses"
              defaultYear={props.defaultYear}
            />
          </Route>
          <Route path="/allNonJustifiedEntrees" nest>
            <UnjustifiedLinesContainer
              mode="recettes"
              defaultYear={props.defaultYear}
            />
          </Route>
          <Route path="/examiner" nest>
            <Examiner year={props.defaultYear} />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default FacturesPanelSelector;
