import { trpc } from "../main/components/ProviderContainer";
import LibelleLine from "./LIbelleLine";
import _ from "underts";

export default function LibellesContainer() {
  const libellés = trpc.libellé.getAll.useQuery().data || [];
  const sorted = _.sortBy(libellés, "libellé");
  return (
    <div>
      {sorted.map((libellé) => (
        <LibelleLine key={libellé._id} {...libellé} />
      ))}
    </div>
  );
}
