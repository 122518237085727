import { useEffect, useState } from "react";
import { produce } from "immer";
import { formaters } from "vincent-utils";
import { DateTime } from "luxon";
import { useVentilations } from "../../ventilations/hooks/useVentilations";
import applyPourcentageVentilations from "../../ventilations/processors/applyPourcentageVentilations";
import removeDummyLinesInVentilations from "../../ventilations/processors/removeDummyLinesInVentilations";
import factureStateValidator from "../validators/factureValidator";
import { v4 as uuid } from "uuid";
import {
  ExistingFacture3,
  Libelle,
  Règlement3,
  Ventilation3,
} from "compta-shared";

type RèglementTypesMap = {
  date: Règlement3["date"] | null;
  montantCent: Règlement3["montantCent"];
};

const updateRèglement = <ITEM extends "date" | "montantCent">(
  règlement: RèglementState,
  item: ITEM,
  value: RèglementTypesMap[ITEM]
) => {
  if (item === "date") {
    return { ...règlement, date: value as Date };
  }
  if (item === "montantCent") {
    return { ...règlement, montantCent: value as number };
  }

  return règlement;
};

const deleteRèglement = (facture: FactureState, index: number) => {
  return produce(facture, (draft) => {
    draft.règlements.splice(index, 1);
  });
};

const onLibelléChange = (
  factureState: FactureState,
  libellé: Libelle | string
) => {
  if (typeof libellé === "string") {
    return { ...factureState, libellé };
  } else {
    return {
      ...factureState,
      libellé: libellé.libellé,
      compte: libellé.compte,
    };
  }
};

const onMontantCentChange = (
  factureState: FactureState,
  montantCent: number
) => {
  if (montantCent !== 0 && montantCent !== factureState.montantCent) {
    return {
      ...factureState,
      montantCent,
      règlements: [], //on efface les règlements si on est en train de mettre un montant général
    };
  }
  return { ...factureState, montantCent };
};

const onModeChange = (
  factureState: FactureState,
  mode: FactureState["mode"]
) => {
  return { ...factureState, mode };
};
const onModeRefChange = (factureState: FactureState, modeRef: string) => {
  return { ...factureState, modeRef };
};

const onDateChange = (factureState: FactureState, date: Date | null) => {
  return { ...factureState, date };
};

const _onUpdateRèglement = <ITEM extends "date" | "montantCent">(
  factureState: FactureState,
  index: number,
  item: ITEM,
  value: RèglementState[ITEM]
) => {
  return produce(factureState, (draft) => {
    if (!draft.règlements[index]) {
      const previous = draft.règlements[index - 1];
      if (!previous) {
        draft.règlements[index] = {
          date: formaters.filterDateTimeForPureDate(new Date()),
          montantCent: 0,
          ventilations: [],
          uuid: uuid(),
          banqueId: null,
        };
        return;
      }
      const newDate = previous.date
        ? DateTime.fromJSDate(previous.date).plus({ month: 1 }).toJSDate()
        : null;
      draft.règlements[index] = {
        date: newDate,
        montantCent: previous.montantCent,
        ventilations: [],
        uuid: uuid(),
        banqueId: null,
      };
    }
    draft.règlements[index] = updateRèglement(
      draft.règlements[index],
      item,
      value
    );
  });
};

const blankRèglement = {
  date: null,
  montantCent: 0,
  ventilations: [],
  banqueId: null,
};
const _conditionnalyAddRèglementLine = (
  factureState: FactureState,
  index: number
): FactureState => {
  const règlements = factureState.règlements;
  //on rajoute une ligne si on est en train de modifier la dernière ligne
  //on ne le fait que si on est en train de modifier d'autres valeurs que la date
  //car la date est prise en charge dans l'update
  if (règlements.length === 0) {
    return {
      ...factureState,
      règlements: [{ ...blankRèglement, uuid: uuid() }],
    };
  }
  if (index === règlements.length - 1) {
    const lastLine = règlements[index];
    const newDate = lastLine.date
      ? DateTime.fromJSDate(lastLine.date).plus({ month: 1 }).toJSDate()
      : null;
    return {
      ...factureState,
      règlements: [
        ...règlements,
        { ...blankRèglement, date: newDate, uuid: uuid() },
      ],
    };
  }
  return factureState;
};

export type FactureState = {
  _id: string | null;
  owner: string | null;
  libellé: ExistingFacture3["libellé"];
  compte: string | null;
  montantCent: ExistingFacture3["montantCent"];
  date: ExistingFacture3["date"];
  mode: ExistingFacture3["mode"] | null;
  modeRef: string;
  banqueId: ExistingFacture3["banqueId"];
  règlements: RèglementState[];
  ventilations: Ventilation3[];
  currentVentilationSelected: null | number | "main";
  docId: string | null;
  validationDate: Date | null;
};

const oldFactureToFactureState = (oldFacture: ExistingFacture3) => {
  return {
    ...oldFacture,
    modeRef: oldFacture.modeRef || "",
    règlements: oldFacture.règlements.map(oldRèglementToRèglementState),
    ventilations: oldFacture.ventilations,
    currentVentilationSelected: null,
    docId: oldFacture.docId,
    validationDate: oldFacture.validationDate,
  } satisfies FactureState;
};

export type RèglementState = Omit<Règlement3, "date"> & {
  date: Date | null;
} & {
  uuid: string;
}; //uuid for React key

const oldRèglementToRèglementState = (oldRèglement: Règlement3) => {
  return {
    ...oldRèglement,
    date: oldRèglement.date,
    ventilations: oldRèglement.ventilations,
    uuid: uuid(),
    banqueId: oldRèglement.banqueId || null,
  };
};

const getDefaultFactureState = (): FactureState => {
  return {
    _id: null,
    owner: null,
    date: formaters.filterDateTimeForPureDate(new Date()),
    libellé: "",
    compte: "",
    montantCent: 0,
    mode: "Virement1",
    modeRef: "",
    règlements: [],
    ventilations: [],
    currentVentilationSelected: null,
    docId: null,
    banqueId: null,
    validationDate: null,
  };
};

export const useFacture = (oldFacture: ExistingFacture3 | null) => {
  const [facture, setFactureState] = useState<FactureState>(
    oldFacture ? oldFactureToFactureState(oldFacture) : getDefaultFactureState()
  );
  const [isTouched, setIsTouched] = useState(false);
  console.log("facture", facture);
  useEffect(() => {
    if (oldFacture) {
      setFactureState(oldFactureToFactureState(oldFacture));
    } else setFactureState(getDefaultFactureState());
  }, [JSON.stringify(oldFacture)]);
  const onFactureLibelléChange = (libellé: string | Libelle) => {
    setIsTouched(true);
    setFactureState((state) => onLibelléChange(state, libellé));
  };
  const onFactureMontantCentChange = (montantCent: number) => {
    setIsTouched(true);
    setFactureState((state) => onMontantCentChange(state, montantCent));
  };
  const onFactureModeChange = (mode: ExistingFacture3["mode"] | null) => {
    setIsTouched(true);
    setFactureState((state) => onModeChange(state, mode));
  };

  const onFactureModeRefChange = (modeRef: string) => {
    setIsTouched(true);
    setFactureState((state) => onModeRefChange(state, modeRef));
  };
  const onFactureDateChange = (date: Date | null) => {
    setIsTouched(true);
    setFactureState((state) => onDateChange(state, date));
  };

  const onFactureCompteChange = (compte: string) => {
    setIsTouched(true);
    setFactureState((state) => ({ ...state, compte }));
  };

  //quand on input un règlement, on regarde si on efface la ventilation générale
  //ou si on peut l'appliquer à chaque ligne de règlement si la ventilation générale n'est consituée que de pourcentages
  const deleteOrApplyMainVentilation = (
    facture: FactureState,
    règlementIndex: number
  ) => {
    if (facture.ventilations.length > 0) {
      return produce(facture, (draft) => {
        //si certaines lignes des ventilations générales n'ont pas de pourcentage, on efface les ventilations générales
        if (draft.ventilations.some((v) => !v.pourcentageCent)) {
          draft.ventilations = [];
        } else {
          //sinon on applique les pourcentages des ventilations générales au réglement qu'on est en train d'éditer
          draft.règlements[règlementIndex].ventilations =
            applyPourcentageVentilations(
              draft.ventilations,
              draft.règlements[règlementIndex].montantCent
            );
        }
      });
    }
    return facture;
  };

  function updateTotal(facture: FactureState) {
    const totalCent = facture.règlements.reduce(
      (acc, règlement) => acc + règlement.montantCent,
      0
    );
    return { ...facture, montantCent: totalCent };
  }
  const onSetRèglementItemValueAtIndex = <ITEM extends "date" | "montantCent">(
    index: number,
    item: ITEM,
    value: RèglementState[ITEM]
  ) => {
    setIsTouched(true);
    setFactureState((facture) => {
      const newFacture = deleteOrApplyMainVentilation(
        _onUpdateRèglement(removeDate(facture), index, item, value),
        index
      );

      if (item === "montantCent") {
        return updateTotal(newFacture);
      }
      return newFacture;
    });
  };

  const onConditionnalyAddRèglementLine = (index: number) => {
    setIsTouched(true);
    setFactureState((facture) =>
      _conditionnalyAddRèglementLine(facture, index)
    );
  };

  const onDeleteRèglementLine = (index: number) => {
    setIsTouched(true);
    setFactureState((facture) => updateTotal(deleteRèglement(facture, index)));
  };
  const currentVentilations =
    facture.currentVentilationSelected !== null &&
    facture.currentVentilationSelected !== "main"
      ? facture.règlements[facture.currentVentilationSelected].ventilations
      : facture.ventilations;

  const _initializeCurrentVentilation = () => {
    setFactureState((state) => {
      const currentVentilationSelected = state.currentVentilationSelected;
      if (typeof currentVentilationSelected === "number") {
        if (
          state.règlements[currentVentilationSelected].ventilations.length === 0
        ) {
          return produce(state, (draft) => {
            draft.règlements[currentVentilationSelected].ventilations = [
              {
                montantCent:
                  state.règlements[currentVentilationSelected].montantCent,
                compte: "",
                pourcentageCent: null,
              },
            ];
          });
        }
      }
      if (currentVentilationSelected === "main") {
        if (state.ventilations.length === 0) {
          return produce(state, (draft) => {
            draft.ventilations = [
              {
                montantCent: state.montantCent,
                compte: "",
                pourcentageCent: null,
              },
            ];
          });
        }
      }
      return state;
    });
  };

  const onSetCurrentVentilationSelected = (index: number | "main" | null) => {
    setFactureState((state) => ({
      ...state,
      currentVentilationSelected: index,
    }));
    _initializeCurrentVentilation();
  };

  const onCancelCurrentFacture = () => {
    setIsTouched(false);
    setFactureState(getDefaultFactureState());
  };

  const {
    ventilations,
    totalActuelVentilationsCent,
    onUpdateVentilation,
    onDeleteVentilation,
    onDeleteAllVentilations,
    targetTotalCent,
    ventilationsErrors,
  } = useVentilations(
    currentVentilations.length > 0 ? currentVentilations : [],
    facture.currentVentilationSelected === "main"
      ? facture.montantCent
      : typeof facture.currentVentilationSelected === "number"
        ? facture.règlements[facture.currentVentilationSelected].montantCent
        : 0
  );

  const removeDate = <T extends { date?: any }>(obj: T) => {
    return {
      ...obj,
      date: null,
    };
  };
  const deleteMainCompte = (facture: FactureState) => {
    return {
      ...facture,
      compte: null,
    };
  };

  const copyVentilationsToFactureState = (state: FactureState) => {
    const ventilationsCleaned = removeDummyLinesInVentilations(ventilations);
    if (state.currentVentilationSelected === "main") {
      //on a des règlements, mais également des pourcentages dans la mainVentilation qu'on est en train d'accepter
      if (
        state.règlements.length > 0 &&
        ventilationsCleaned.every((v) => v.pourcentageCent)
      ) {
        return produce(state, (draft) => {
          draft.ventilations = ventilationsCleaned;
          draft.règlements.forEach((règlement) => {
            règlement.ventilations = applyPourcentageVentilations(
              ventilationsCleaned,
              règlement.montantCent
            );
          });
        });
      }
      //on a des règlements, mais pas de pourcentages dans la mainVentilation qu'on est en train d'accepter
      //on efface les ventilations des règlements
      if (state.règlements.length > 0) {
        return {
          ...state,
          ventilations: ventilationsCleaned,
          règlements: state.règlements.map((règlement) => ({
            ...règlement,
            ventilations: [],
          })),
        };
      }
      //cas normal sans règlements
      return {
        ...state,
        ventilations: ventilationsCleaned,
      };
    } else if (state.currentVentilationSelected !== null) {
      const règlements = state.règlements.map((règlement, index) =>
        index === state.currentVentilationSelected
          ? {
              ...règlement,
              ventilations: removeDummyLinesInVentilations(ventilations),
            }
          : règlement
      );
      return { ...state, règlements };
    }
    return state;
  };

  // const onCloseVentilation = (state:FactureState) => {
  //   return {...state, currentVentilationSelected: null };
  // }
  const onAcceptVentilations = () => {
    setIsTouched(true);
    setFactureState(copyVentilationsToFactureState);
    setFactureState(deleteMainCompte);
  };

  const factureErrors = factureStateValidator(facture);

  const factureProps = {
    onFactureCompteChange,
    onFactureDateChange,
    onFactureLibelléChange,
    onFactureMontantCentChange,
    onFactureModeChange,
    onFactureModeRefChange,
    onCancelCurrentFacture,
    factureErrors,
    isTouched,
    setIsNotTouched: () => setIsTouched(false),
  };

  const règlementsProps = {
    onSetRèglementItemValueAtIndex,
    onConditionnalyAddRèglementLine,
    onDeleteRèglementLine,
    totalActuelVentilationsCent,
  };

  const ventilationsProps = {
    onSetCurrentVentilationSelected,
    ventilations,
    ventilationsErrors,
    totalActuelVentilationsCent,
    ventilationTotalTargetCent: targetTotalCent,
    onUpdateVentilation,
    onDeleteVentilation,
    onDeleteAllVentilations,
    onAcceptVentilations,
  };

  return {
    facture,
    factureProps,
    règlementsProps,
    ventilationsProps,
  };
};

export {
  onMontantCentChange,
  onModeRefChange,
  onDateChange,
  onModeChange,
  onLibelléChange,
};
