import { financePercentageCent } from "vincent-utils";
import { Ventilation3 } from "compta-shared";

const applyPourcentageVentilations = (
  ventilations: Ventilation3[],
  montantCent: number
) => {
  if (ventilations.some((v) => !v.pourcentageCent)) {
    throw new Error(
      "percentageCent cannot be null  in applyPourcentageVentilations"
    );
  }
  const pourcentages = ventilations.map((v) => v.pourcentageCent) as number[];
  const montants = financePercentageCent(montantCent, pourcentages);
  return ventilations.map((ventilation, idx) => {
    return {
      compte: ventilation.compte,
      montantCent: montants[idx],
      pourcentageCent: ventilation.pourcentageCent,
    };
  });
};

export default applyPourcentageVentilations;
